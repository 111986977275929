<template>
    <div class="medicineBox">
        <div>
            <div :class="s.everyRaw">
                <h4>添加药品名称</h4>
                <div :class="s.checkBoxItem">
                    <el-input
                        v-model="ZADRUGLISTPRE"
                        placeholder="请添加药品内容"
                        style="width: 273px"
                        clearable
                    ></el-input>
                    <el-button
                        plain
                        style="
                            margin-left: 8px;
                            color: #31b4b0;
                            border: 1px solid #31b4b0;
                        "
                        @click="addZAlist"
                        >添加</el-button
                    >
                </div>
            </div>
            <div :class="s.everyRaw">
                <h4>待检索药品</h4>
                <div :class="[s.checkBoxItem, s.borderBox]">
                    <el-tag
                        closable
                        @close="handleCloseTag(item)"
                        v-for="(item, index) in drugNameList"
                        :key="index"
                        color="#fff"
                        :class="s.tags"
                        >{{ item }}</el-tag
                    >
                </div>
            </div>

            <div :class="s.everyRaw">
                <h4></h4>
                <div :class="s.grayInfo">
                    已选择药品{{ drugNameList.length || 0 }}件
                </div>
            </div>

            <el-button
                style="
                    margin: 10px auto 10px;
                    background: #31b4b0;
                    border-radius: 4px;
                    color: #fff;
                    display: block;
                "
                @click="queryZAdrug"
                >药房检索</el-button
            >
            <!-- 检索结果 -->
            <div :class="s.medBox">
                <h3>检索结果<span></span></h3>

                <el-table
                    :data="allDrugList"
                    style="width: 100%; text-align: center"
                    header-row-class-name="textCenter"
                >
                    <el-table-column prop="drugCommonName" label="药品通用名">
                    </el-table-column>
                    <el-table-column prop="drugDosageForm" label="剂型">
                    </el-table-column>
                    <el-table-column prop="drugManufactorName" label="厂家">
                    </el-table-column>
                    <el-table-column prop="drugSpecifications" label="规格">
                    </el-table-column>
                    <el-table-column prop="drugSellingPrice" label="售价">
                    </el-table-column>
                    <el-table-column prop="drugClassification" label="药品类型">
                    </el-table-column>
                    <el-table-column label="操作">
                        <template slot-scope="scope">
                            <el-button
                                size="mini"
                                @click="handleEdit(scope.$index, scope.row)"
                                >添加</el-button
                            >
                        </template></el-table-column
                    >
                </el-table>
            </div>
            <div :class="[s.grayInfo, s.fixMargin]">
                可选药品清单（单处方限5种药品）
            </div>

            <!-- 可添加药品 -->
            <div :class="s.medBox">
                <el-table :data="choosenDrugList" style="width: 100%">
                    <el-table-column prop="drugCommonName" label="药品通用名">
                    </el-table-column>
                    <el-table-column prop="drugDosageForm" label="剂型">
                    </el-table-column>
                    <el-table-column prop="drugManufactorName" label="厂家">
                    </el-table-column>
                    <el-table-column prop="drugSpecifications" label="规格">
                    </el-table-column>
                    <el-table-column prop="drugSellingPrice" label="售价">
                    </el-table-column>
                    <el-table-column prop="drugClassification" label="药品类型">
                    </el-table-column>

                    <el-table-column label="操作">
                        <template slot-scope="scope">
                            <el-button
                                size="mini"
                                @click="handleDelete(scope.$index, scope.row)"
                                >删除</el-button
                            >
                        </template></el-table-column
                    >
                </el-table>
            </div>
            <div :class="[s.grayInfo, s.fixMargin]">
                已选择药品{{ choosenDrugList.length || 0 }}件,还可添加{{
                    5 - choosenDrugList.length
                }}件药品
            </div>
        </div>
        <!-- <div class="medicineBoxside">
            <el-card class="box-card">
                <div>
                    <el-input
                        v-model="drugNames"
                        placeholder="请输入药品名称"
                        style="width: 60%"
                        clearable
                    ></el-input>
                    <el-button
                        type="primary"
                        style="margin-left: 30px"
                        @click="handelSerach"
                        >搜索</el-button
                    >
                </div>
                <div class="drugs-section">
                    <div class="drugs-title flex-span">
                        <span>名称 </span>
                        <span>国药准字 </span>
                        <span> </span>
                    </div>
                    <div
                        class="drugs-list"
                        v-infinite-scroll="searchMedicin"
                        infinite-scroll-disabled="disabled"
                    >
                        <div
                            class="flex-span"
                            v-for="(item, ind) in drugsList"
                            :key="ind"
                        >
                            <span>{{ item && item.drugCommonName }}</span>
                            <span>{{ item && item.approvalNo }}</span>
                            <el-button
                                type="success"
                                class="addBtn"
                                @click="addMedicin(item)"
                                size="mini"
                                :disabled="item.inventory <= 0 ? true : false"
                                >添加</el-button
                            >
                        </div>
                    </div>
                </div>
            </el-card>
        </div>
        <div class="medicineBoxside2">
            <el-card class="box-card">
                <div class="itemBox itemBox2">
                    <div class="medicine-title">
                        <span>名称</span>
                        <span>数量</span>
                    </div>
                    <div
                        class="medicineItem"
                        v-for="(item, ind) in sendList"
                        :key="ind"
                    >
                        <div>{{ item.drugName }}</div>
                        <div>
                            <el-input-number
                                v-model="item.num"
                                :min="0"
                                label="描述文字"
                                size="mini"
                                @change="medicineChange(item.num, ind)"
                                :max="item.total"
                            ></el-input-number>
                        </div>
                    </div>
                </div>
            </el-card>
        </div> -->
    </div>
</template>

<script>
const apiUrl = {
    queryZAdrug: 'drugs/queryDrugsByNames', // 查询药品列表
};
export default {
    props: {
        distributeData: {
            type: Object,
            required: true,
        },
        patientInfo: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            loading: false,
            drugNameList: [],
            sendList: [],
            allDrugList: [],
            ZADRUGLISTPRE: '',
            choosenDrugList: [],
        };
    },
    watch: {},
    methods: {
        handleCloseTag(tag) {
            this.drugNameList.splice(this.drugNameList.indexOf(tag), 1);
        },
        resetFormData() {
            // this.allDrugList = [];
            // this.form.isEnd = false;
            // this.choosenDrugList = [];
            Object.assign(this.$data, this.$options.data.call(this));
        },
        handleEdit(index, row) {
            row.num = 1;
            this.choosenDrugList.indexOf(row) === -1 &&
                this.choosenDrugList.push(row);
        },
        handleDelete(index, row) {
            this.choosenDrugList.splice(index, 1);
        },
        async queryZAdrug() {
            this.choosenDrugList = [];
            const { uuid, inquiryNo, businessType } = this.patientInfo;
            const postData = {
                drugNameList: this.drugNameList,
                inquiryNo,
                businessType,
                distributeType: this.distributeData.userDistributeType,
                uuid,
            };
            const { data: res } =
                (await this.$http.post(apiUrl.queryZAdrug, postData)) || {};
            const { code, msg, data = [] } = res;
            if (code !== 0) {
                this.$notify({
                    title: '错误',
                    message: msg,
                    type: 'error',
                });
                return;
            }
            console.log(data);
            this.allDrugList = data;
            this.drugNameList = [];
        },
        addZAlist() {
            if (this.ZADRUGLISTPRE && this.drugNameList.length < 5) {
                this.drugNameList.push(this.ZADRUGLISTPRE);
                this.ZADRUGLISTPRE = '';
            }
        },
    },
    computed: {
        noMore() {
            return this.drugsList.length >= this.total;
        },
        disabled() {
            return this.loading || this.noMore;
        },
        s() {
            return this.$style;
        },
    },
};
</script>

<style lang="scss" module>
h3,
h4,
h5 {
    margin: 0 auto;
}
.tags {
    margin: 0 8px;
    height: 32px;
    max-width: 80px;
    overflow: hidden;
    text-overflow: ellipsis;
    position: relative;
    padding-right: 20px;

    .el-icon-close {
        position: absolute;
        top: 50%;
        right: 5px;
        transform: translateY(-50%);
    }
}
.everyRaw {
    display: flex;
    align-items: center;
    margin-top: 20px;
    h4 {
        margin: 0;
        display: inline-block;
        width: 110px;
        text-align: right;
        margin-right: 14px;
        color: #666666;
    }
    .checkBoxItem {
        width: 100%;
        :global {
            .el-checkbox {
                width: 150px;
            }
        }
    }
}
.borderBox {
    border: 1px solid #e5e5e5;
    height: 44px;
    margin-right: 30px;
    display: flex;
    align-items: center;
}
.grayInfo {
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #999999 !important;
    margin-bottom: 15px;
}
.searchBox {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #666666;
    padding-left: 22px;
    height: 40px;
    margin-top: 14px;
    margin-bottom: 11px;
    .searchBoxInput {
        width: 448px;
        height: 40px;
        border-radius: 4px;
        border: 1px solid #e5e5e5;
        margin: 0px 8px 0px 6px;
    }
}
.medBox {
    margin: 0 0px 17px;
    padding-bottom: 30px;
    border: 1px solid #e7e7e7;
    h3 {
        height: 40px;
        background: #fbfbfb;
        line-height: 40px;
        text-indent: 20px;
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #666666;
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #f0f0f0;
        margin: 0 !important;
        span {
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #999999;
        }
    }
    .everyRaw {
        display: flex;
        align-items: center;
        margin-top: 20px;
        h4 {
            margin: 0;
            display: inline-block;
            width: 110px;
            text-align: right;
            margin-right: 14px;
            color: #666666;
        }
        .checkBoxItem {
            width: 100%;
            :global {
                .el-checkbox {
                    width: 150px;
                }
            }
        }
    }
}
</style>
