<template>
    <div class="doctorConfirm">
        <!-- <h3>患者信息</h3>
        <div class="formBody" v-show="patientInfo.uuid">
            <el-card class="box-card">
                <ul>
                    <li
                        v-for="(item, index) in fPatientInfo"
                        :key="index"
                        class="text item"
                    >
                        <span>{{ item.label }}</span
                        >{{ item.value }}
                    </li>
                </ul>
            </el-card>
        </div> -->
        <el-dialog
            title="问诊结果"
            :visible.sync="isOver"
            width="65%"
            top="5vh"
            :show-close="false"
            :close-on-click-modal="false"
            :close-on-press-escape="false"
        >
            <div class="modal-out">
                <div ref="doctorConfirm">
                    <div class="modal-top">
                        <el-card class="box-card" style="margin-bottom: 30px">
                            <h3>患者信息</h3>
                            <ul>
                                <li
                                    v-for="(item, index) in fPatientInfo"
                                    :key="index"
                                    class="text item"
                                >
                                    <span>{{ item.label }}</span
                                    >{{ item.value }}
                                </li>
                            </ul>
                        </el-card>
                        <h3>出具诊断</h3>
                        <el-form
                            ref="Form"
                            :model="form"
                            label-width="100px"
                            :rules="rules"
                        >
                            <el-form-item
                                label="是否复诊"
                                prop="subsequentFlag"
                            >
                                <el-radio
                                    v-model="form.subsequentFlag"
                                    :label="true"
                                    >是</el-radio
                                >
                                <el-radio
                                    v-model="form.subsequentFlag"
                                    :label="false"
                                    >否</el-radio
                                >
                            </el-form-item>
                            <el-form-item label="是否意外" prop="isAccident">
                                <el-radio
                                    v-model="form.isAccident"
                                    :label="true"
                                    >是</el-radio
                                >
                                <el-radio
                                    v-model="form.isAccident"
                                    :label="false"
                                    >否</el-radio
                                >
                            </el-form-item>
                            <el-form-item label="是否本人" prop="isSelf">
                                <el-radio v-model="form.isSelf" :label="true"
                                    >是</el-radio
                                >
                                <el-radio v-model="form.isSelf" :label="false"
                                    >否</el-radio
                                >
                            </el-form-item>
                            <el-form-item label="是否既往症" prop="isAnamnesis">
                                <el-radio
                                    v-model="form.isAnamnesis"
                                    :label="true"
                                    >是</el-radio
                                >
                                <el-radio
                                    v-model="form.isAnamnesis"
                                    :label="false"
                                    >否</el-radio
                                >
                            </el-form-item>
                            <el-form-item
                                label="疾病分类"
                                prop="diseasesType"
                                :class="s.flexBox"
                            >
                                <el-select v-model="form.diseasesType">
                                    <el-option
                                        v-for="item in diseasesTypeOption"
                                        :key="item.value"
                                        :label="item.label"
                                        :value="item.value"
                                    >
                                    </el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item
                                label="疾病类型"
                                prop="diagCodeType"
                                :class="s.flexBox"
                            >
                                <el-select v-model="form.diagCodeType">
                                    <el-option
                                        v-for="item in diagCodeTypeOption"
                                        :key="item.value"
                                        :label="item.label"
                                        :value="item.value"
                                    >
                                    </el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item label="现病史" prop="presentIllness">
                                <el-input
                                    type="textarea"
                                    v-model="form.presentIllness"
                                ></el-input>
                            </el-form-item>
                            <el-form-item label="诊断结果" prop="result">
                                <el-input
                                    type="textarea"
                                    v-model="form.result"
                                ></el-input>
                            </el-form-item>
                            <el-form-item label="诊断建议" prop="suggest">
                                <el-input
                                    type="textarea"
                                    v-model="form.suggest"
                                ></el-input>
                            </el-form-item>
                            <el-form-item
                                label="是否开药"
                                prop="recipeFlag"
                                class="item-two"
                            >
                                <el-radio
                                    v-model="form.recipeFlag"
                                    :label="true"
                                    :disabled="fromOrigin === 'MIUS'"
                                    >是</el-radio
                                >
                                <el-radio
                                    v-model="form.recipeFlag"
                                    :label="false"
                                    >否</el-radio
                                >
                            </el-form-item>
                        </el-form>
                    </div>
                    <div v-show="form.recipeFlag">
                        <div class="everyRaw">
                            <h4>配送形式</h4>

                            <el-radio
                                v-model="userDistributeType"
                                :disabled="!distributeType.includes('1')"
                                :label="'1'"
                                >快递</el-radio
                            >
                            <el-radio
                                v-model="userDistributeType"
                                :disabled="!distributeType.includes('2')"
                                :label="'2'"
                                >同城配送</el-radio
                            >
                            <el-radio
                                v-model="userDistributeType"
                                :disabled="!distributeType.includes('3')"
                                :label="'3'"
                                >到店自取</el-radio
                            >
                        </div>
                        <Medicine
                            ref="refMed"
                            :distributeData="{
                                distributeType: this.distributeType,
                                userDistributeType: this.userDistributeType,
                            }"
                            :patientInfo="patientInfo"
                        />
                    </div>
                    <div class="modal-bottom">
                        <el-button type="primary" @click="onSubmit"
                            >确认提交</el-button
                        >
                    </div>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import html2canvas from 'html2canvas';
import Medicine from './medicine';
import debounce from 'lodash/debounce';
let apiUrl = {
    diaGnose: '/trtc/diagnose', //诊断结果提交
    base64Url: '/sys/oss/upload/base64', //base获取图片地址
    outputMedicine: '/prescription/prescribe', //开药
};

const DICTIONARIES = {
    name: '姓名',
    idCard: '身份证号',
    chiefComplaint: '问诊主诉',
    weight: '体重',
    allergicHistory: '过敏史',
    medicalHistory: '过往病史',
    medicalRecord: '病例资料',
    other: '其他',
    mobile: '手机',
    relation: '与用户关系',
    clinicDepartment: '就诊科室',
    presentHistory: '现病史',
    lactationFlag: '哺乳期',
    gestationFlag: '妊娠期',
    age: '年龄',
    fGender: '性别', // 0：男 1：女 2：保密
    //    avatar: '患者头像'
    appDesc: '渠道',
};

export default {
    components: {
        Medicine,
    },
    props: {
        patientInfo: {
            type: Object,
            required: true,
        },
        isOver: {
            type: Boolean,
            required: true,
        },
        fromOrigin: {
            type: String,
            required: true,
        },
    },
    watch: {
        patientInfo(val) {
            if (!val) return;
            this.distributeType = this.flatten(this.patientInfo.distributeType);
            this.userDistributeType = this.patientInfo.userDistributeType;
            console.log(this.distributeType, this.userDistributeType);
        },
        fromOrigin(val) {
            // 'ZHONGAN','MIUS'
            console.log(val, '1111111');
            if (val === 'MIUS') {
                this.form.recipeFlag = false;
            }
        },
    },
    data() {
        return {
            distributeType: [],
            userDistributeType: '',
            toDataURL: '',
            dialogVisible: true,
            form: {
                result: '',
                suggest: '',
                recipeFlag: false,
                subsequentFlag: true,
                diseasesType: '1',
                diagCodeType: 'ICD10',
                presentIllness: '', // 现病史
                isSelf: true, // 是否本人
                isAnamnesis: false, // 是否既往症
                isAccident: true, // 是否意外
                isEnd: false, // 是否已经诊断
            },
            rules: {
                result: [
                    { required: true, message: '诊断结果', trigger: 'blur' },
                ],
                suggest: [
                    { required: true, message: '诊断建议', trigger: 'blur' },
                ],
                presentIllness: [
                    { required: true, message: '现病史', trigger: 'blur' },
                ],
                isSelf: [{ required: true, message: '是否本人' }],
                isAnamnesis: [{ required: true, message: '是否既往症' }],
                isAccident: [{ required: true, message: '是否意外' }],
                diseasesType: [{ required: true, message: '疾病分类' }],
                diagCodeType: [{ required: true, message: '疾病类型' }],
                subsequentFlag: [{ required: true, message: '是否复诊' }],
            },
            diseasesTypeOption: [
                {
                    value: '1',
                    label: '普通',
                },
                {
                    value: '2',
                    label: '急性病',
                },
                {
                    value: '3',
                    label: '慢性病',
                },
                {
                    value: '9',
                    label: '未知',
                },
            ], // 疾病分类（1：普通，2：急性病，3：慢性病，9：未知）

            diagCodeTypeOption: [
                {
                    value: 'ICD10',
                    label: '西医',
                },
                {
                    value: 'GB95',
                    label: '中医',
                },
            ], //  疾病类型（ICD10：西医，GB95：中医）
        };
    },
    methods: {
        flatten(arr) {
            console.log(arr, 'arr');
            if (!arr) return '';
            return JSON.parse(arr.replace(/'/g, '')).map((item) =>
                String(item)
            );
        },
        convertToImage(container, options = {}) {
            // 设置放大倍数
            const scale = window.devicePixelRatio;

            // 传入节点原始宽高
            const width = container.offsetWidth;
            const height = container.offsetHeight;
            // html2canvas配置项
            const ops = {
                scale,
                width,
                height,
                useCORS: true,
                allowTaint: false,
                ...options,
            };

            return html2canvas(container, ops).then((canvas) => {
                // 返回图片的二进制数据
                console.log(canvas.toDataURL('image/png'));
                return canvas.toDataURL('image/png');
            });
        },

        async prescription() {
            const { uuid } = this.patientInfo;
            await this.getImage();
            let postData = {
                uuid,
                drugsList: this.$refs.refMed.choosenDrugList,
                fileList: [this.fileSrc],
                avatar: 'https://store.saastest.miaoins.com/mius/20210628/4f4da94a68c54e718b76d01bfc9e4789.png',
            };
            const res = await this.$http.post(apiUrl.outputMedicine, postData);
            if (~~res.data.code !== 0) {
                this.$notify({
                    title: '错误',
                    message: res.data.msg,
                    type: 'error',
                });
                throw new Error(`开药失败`);
            }
            // this.$refs.refMed.drugNameList = [];
            // this.$refs.refMed.allDrugList = [];
            // this.$refs.refMed.choosenDrugList = [];
            Object.assign(
                this.$refs.refMed.$data,
                this.$refs.refMed.$options.data.call(this)
            );
            this.$refs.refMed.resetFormData();

            let url = res.data.data.url;

            // let newWindow = window.open();
            // newWindow.location.href = url;
            window.open(url);
        },
        async getImage() {
            const dom = this.$refs.doctorConfirm;
            this.toDataURL = await this.convertToImage(dom);
            const res = await this.$http.post(apiUrl.base64Url, {
                base64: this.toDataURL.substring(22),
                extension: 'png',
            });

            this.fileSrc = res.data.data.src;
        },
        objHandle(obj) {
            for (let key in obj) {
                if (obj[key] === '') {
                    delete obj[key];
                }
            }
            return obj;
        },
        onSubmit: debounce(
            async function () {
                this.$refs['Form'].validate(async (valid) => {
                    if (!valid) {
                        this.$notify({
                            title: '错误',
                            message: '请检查诊断信息是否已完成',
                            type: 'error',
                        });

                        return;
                    }
                    const {
                        result,
                        suggest,
                        recipeFlag,
                        subsequentFlag,
                        isSelf,
                        isAnamnesis,
                        isAccident,
                    } = this.form;
                    const { uuid, name, chiefComplaint, doctorId } =
                        this.patientInfo;
                    if (
                        recipeFlag &&
                        !this.$refs.refMed.choosenDrugList.length
                    ) {
                        this.$message({
                            message: '请选择药品',
                            type: 'error',
                        });
                        return;
                    }
                    const postData = {
                        ...this.objHandle(this.form),
                        subsequentFlag: subsequentFlag ? 'Y' : 'N', //是否复诊
                        uuid, //诊断唯一标识
                        result, //诊断结果
                        suggest, //诊断建议
                        doctorId, //医生id
                        //   doctorName: '', //医生姓名
                        //   doctorAvatar: '', //医生头像
                        //   doctorDepartment: '', //医生科室
                        // inTime: '', //问诊时间
                        //   prescriptionReport: '', //处方报告
                        // evaluate: '', //评价
                        chiefComplaint, //主诉
                        name, //患者姓名
                        recipeFlag: recipeFlag ? 'Y' : 'N', //是否开具处方 Y:是 N
                        isSelf: isSelf ? 'Y' : 'N', // 是否本人
                        isAnamnesis: isAnamnesis ? 'Y' : 'N', // 是否既往症
                        isAccident: isAccident ? 'Y' : 'N', // 是否意外
                        // rimg: '', //处方图片
                        // rsn: '', //处方号
                        // recipeTime: '', //处方开具时间
                        noticeType: 3, //正常结束: 1、异常结束: 2，诊断及处方：3
                        distributeType: this.userDistributeType,
                    };
                    const loading = this.$loading({
                        lock: true,
                        text: '提交中',
                        spinner: 'el-icon-loading',
                        background: 'rgba(0, 0, 0, 0.7)',
                    });

                    if (recipeFlag) {
                        const flag = await this.prescription()
                            .then(() => {
                                loading.close();
                                return true;
                            })
                            .catch((error) => {
                                loading.close();
                                console.log(error);
                                return false;
                            });
                        if (!flag) return;
                    }
                    loading.close();
                    const res = await this.$http.post(
                        apiUrl.diaGnose,
                        postData
                    );

                    if (res.data.code == '0') {
                        this.$message({
                            message: '提交成功',
                            type: 'success',
                        });
                        this.$emit('allOver', true);
                        this.resetFormData();
                        this.$emit('closeAgree', true);
                        return;
                    }
                    this.$message({
                        message: res.data.msg,
                        type: 'error',
                    });
                });
            },
            3000,
            { leading: true, trailing: false }
        ),
        resetFormData() {
            this.$refs['Form'].resetFields();

            // Object.assign(this.$data, this.$options.data.call(this));
        },
    },

    computed: {
        fPatientInfo() {
            return Object.keys(DICTIONARIES).map((item) => {
                return {
                    label: DICTIONARIES[item],
                    value: this.patientInfo[item],
                };
            });
        },
        s() {
            return this.$style;
        },
    },
};
</script>

<style lang="scss" module>
.everyRaw {
    display: flex;
    h4 {
        width: 100px;
        text-align: right;
        margin-bottom: 15px;
    }
}
.modal-out {
    height: 80vh;
    overflow-y: auto;
    .modal-bottom {
        margin-top: 20px;
        display: flex;
        justify-content: space-around;
    }
    .modal-top {
        width: 70%;
    }
    & > div {
        padding: 10px;
    }
}
.doctorConfirm {
    height: 100%;
    overflow-y: scroll;
    padding: 0 10px;

    .text {
        font-size: 14px;
    }

    .item {
        padding: 9px 0;
    }

    .box-card {
        width: 100%;
        ul {
            display: flex;
            flex-wrap: wrap;
        }
        li {
            width: 50%;
            list-style: none;
            font-size: 14px;
            span {
                color: #606266;
                display: inline-block;
                width: 90px;
            }
        }
    }
}
</style>
<style lang="scss">
.modal-out {
    height: 80vh;
    overflow-y: auto;
    .modal-bottom {
        margin-top: 20px;
        display: flex;
        justify-content: space-around;
    }
    .modal-top {
        width: 70%;
    }
    & > div {
        padding: 10px;
    }
}
.doctorConfirm {
    height: 100%;
    overflow-y: scroll;
    padding: 0 10px;

    .text {
        font-size: 14px;
    }

    .item {
        padding: 9px 0;
    }

    .box-card {
        width: 100%;
        ul {
            display: flex;
            flex-wrap: wrap;
        }
        li {
            width: 50%;
            list-style: none;
            font-size: 14px;
            span {
                color: #606266;
                display: inline-block;
                width: 90px;
            }
        }
    }
    .everyRaw {
        display: flex;
        h4 {
            display: block;
            width: 90px;
            text-align: right;
            margin: 0 15px 0 0;
        }
    }
}
</style>
